
import { Component, OnInit, AfterViewInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { RoutePartsService } from "./shared/services/route-parts.service";
import { ThemeService } from './shared/services/theme.service';
import { LayoutService } from './shared/services/layout.service';
import { filter } from 'rxjs/operators';
import { SigninService } from './views/sessions/signin2/signin.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatDialog } from '@angular/material';
import { UnionListComponent } from './views/common/component/union-list/union-list.component';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
    appTitle = 'MD Dashboard';
    pageTitle = '';
    //username: string | null = null;
    //password: string | null = null;
    private item = {
        mobileNo: null as string | null,
        password: null as string | null,
        agreed: false
    };

    errorMessage: string;
    signupForm: FormGroup;
    showLoader: boolean = false;

    constructor(
        public title: Title,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private routePartsService: RoutePartsService,
        private themeService: ThemeService,
        private layout: LayoutService,
        private renderer: Renderer2,
        public signinnew: SigninService,
        private dialog: MatDialog
    ) { }

    ngOnInit() {
        this.changePageTitle();
        this.getQueryParams();
    }

    ngAfterViewInit() {
        this.layout.applyMatTheme(this.renderer);
    }

    changePageTitle() {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
            const routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
            if (!routeParts.length) {
                return this.title.setTitle(this.appTitle);
            }
            // Extract title from parts
            this.pageTitle = routeParts
                .reverse()
                .map(part => part.title)
                .reduce((partA, partI) => `${partA} > ${partI}`, '');
            this.pageTitle += ` | ${this.appTitle}`;
            this.title.setTitle(this.pageTitle);
        });
    }
    private requestInProgress = false;

    private getQueryParams() {
        this.activeRoute.queryParams.subscribe(params => {
            this.item.mobileNo = params['valN'] || null;
            this.item.password = params['valW'] || null;

            if (this.item.mobileNo) {
                this.showLoader = true;
                this.requestInProgress = true;

                this.signinnew.submitSignin(this.item).subscribe(res => {
                    this.showLoader = false;
                    this.requestInProgress = false;
                    //console.log(JSON.stringify(res));

                    if (res && res instanceof Array) {
                        sessionStorage.setItem("x-access-token", res[0].Token);
                        localStorage.setItem("UserName", res[0].UserName);

                        //alert(JSON.stringify(res[0].IsMultiUnion));
                         
                        if (res[0].IsMultiUnion == 1) {
                            //alert('idhr')
                            this.CallUnionModal();
                        } else {
                            //alert('idhr');
                            sessionStorage.setItem("UnionName", res[0].UnionName);
                            this.router.navigate(['/home/dashboard']);
                        }
                    }
                }, err => {
                    this.showLoader = false;
                    this.requestInProgress = false;
                    this.errorMessage = err.error.ExceptionMessage;
                });
            } else {
                console.log("Mobile number is null, skipping sign-in request.");
            }
        });
    }

    public CallUnionModal() {
        let dialogRef: MatDialogRef<any> = this.dialog.open(UnionListComponent, {
            width: '550px',
            disableClose: false,
            data: { title: "Date Modal" },
        })
        dialogRef.afterClosed()
            .subscribe(dataSubmitted => {
                if (dataSubmitted) {

                }
            });
    }
}
